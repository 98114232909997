import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  ConceptMedia,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout checkjump="privacy/site" checken="en">
      <SEO  checken="en" title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'SITE TERMS OF USE',
            sub: 'Site Terms of Use',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/privacy/site/kv.png',
              },
              imgSp: {
                src: '/assets/images/privacy/site/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
         checken="en"
          data={{
            parent: [
              {
                label: 'Terms and Conditions',
                path: '/en/privacy/',
              },
            ],
            current: {
              label: 'Site Terms of Use',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap exClass="u_mbExLarge">
          <h2 className="c_headingLv2">Site Terms of Use</h2>
          <h3 className="c_headingLv5">1. Terms of Use</h3>
          <p className="u_mb25">
          This website (all directories under https://www.yrph.com/ as well as various reservation and e-mail programs; hereinaftercollectively referred to as the "Site") is operated by Mitsubishi Estate Hotels & Resorts Co., Ltd.  (the "Company") or its agents, and the Company hasestablished these Site Terms of Use for customers using the Site. Please read the following Site Terms of Use before using the Site. By using the Site, you agree to be bound by the following Terms of Use. The Site Terms of Use are subject to change without notice, so please be sure to check this page for the most up-to-date information.

          </p>
          <h3 className="c_headingLv5">2. Changes in service content</h3>
          <p className="u_mb25">
          The Company reserves the right to change the content of the services provided on the Site without prior notice, as well as the right to temporarily or permanently discontinue operation of all or part of the Site without notice due to maintenance work, system failure, or other reasons.

          </p>
          <h3 className="c_headingLv5">3. Copyrights</h3>
          <p className="u_mb25">
          All copyrights to information posted on the Site belong in principle to the Company. Secondary use of information posted on the Site is prohibited, except with the written consent of the Company.

          </p>
          <h3 className="c_headingLv5">4. Disclaimer</h3>
          <p className="u_mb25">
          The Company takes the utmost care with regard to information posted on the Site but does not guarantee its completeness. Priority will be given to the latest information available when posting details about the Company's business operations. The Company shall not be liable whatsoever for any damages incurred by users as a result of using the Site. The Company also assumes no responsibility for any damages incurred by users as a result of service interruptions due to maintenance work, system failures, or other reasons.


          </p>
          <h3 className="c_headingLv5">5.  Linking to the Site</h3>
          <p className="u_mb25">
          You are free as a general rule to link to the Site. However, the URL of each file is subject to change without notice, so please be sure to link to the homepage (https://www.yrph.com/). Please refrain from linking only to images or using them within frames. If you wish to link to the Site, please notify us in advance.

          </p>
          <h3 className="c_headingLv5">6. Disclaimer of liability for links</h3>
          <p className="u_mb25">
          The Site may contain links to the websites of Group hotels, affiliated facilities, and other third parties to supplement the information provided on the Site. The content of third-party websites linked to from the Site is the sole responsibility of the relevant third parties. The Company assumes no responsibility for the content of linked third-party websites or for any harm that may result from the use of such websites.
          </p>
        </LWrap>
      </section>
      {/* <ConceptMedia /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
